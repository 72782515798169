import React from 'react';

import { VisuallyHidden } from '@chakra-ui/react';

type HiddenSeoHeadingProps = {
  heading: string;
};

export const HiddenSeoHeading = ({ heading }: HiddenSeoHeadingProps) => {
  return (
    <VisuallyHidden>
      <h1>{heading}</h1>
    </VisuallyHidden>
  );
};
