import { ReactElement, useEffect } from 'react';

import { GetStaticProps } from 'next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { useRouter } from 'next/router';

import { HiddenSeoHeading } from '@/components/functional/HiddenSEOHeading/HiddenSEOHeading';
import { DefaultLayout, Footer } from '@/components/layouts';
import { DynamicBlockSelector } from '@/components/sections/dynamic-blocks/DynamicBlockSelector';
import { LetsMeet } from '@/components/sections/home';
import { PropsBuilder } from '@/graphql/api';
import { useGetGlobalQuery, useGetHomeQuery } from '@/graphql/generated';
import { useLocale } from '@/hooks';
import { AvailableLanguages, DEFAULT_LOCALE, LOCALE, LOCALES, PAGE_REVALIDATE_TIME } from '@/utils/constants';
import { getNavigatorLanguage } from '@/utils/helper-functions/lang';

import { NextPageWithLayout } from './_app';

const REDIRECTION_ON_NAVIGATOR_LANG = false;

const Home: NextPageWithLayout = () => {
  const locale = useLocale();
  const router = useRouter();
  const { blocks, letsMeet } = useGetHomeQuery({
    locale,
  }).data?.home?.data?.attributes!;

  useEffect(() => {
    if (window && REDIRECTION_ON_NAVIGATOR_LANG) {
      const navigatorLang = getNavigatorLanguage(window.navigator.language);
      if (Object.values(LOCALE).includes(navigatorLang as AvailableLanguages)) {
        router.replace(navigatorLang);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <HiddenSeoHeading heading="Homepage" />
      {blocks.map((block, index) => (
        <DynamicBlockSelector key={`${block?.__typename}-${index}`} typename={block?.__typename} {...block} />
      ))}
      <LetsMeet data={letsMeet} />
    </>
  );
};

Home.getLayout = (page: ReactElement) => {
  const { metadata } = page.props;

  return (
    <DefaultLayout metadata={metadata}>
      {page}
      <Footer />
    </DefaultLayout>
  );
};

export const getStaticProps: GetStaticProps = async ({ locale }) => {
  const propsBuilder = new PropsBuilder();

  propsBuilder.withQuery(useGetGlobalQuery, {
    variables: {
      locale,
    },
  });
  propsBuilder.withQuery(useGetHomeQuery, {
    variables: {
      locale,
    },
    getMetadata: (data) => data.home?.data?.attributes?.metadata,
  });

  const lang = await serverSideTranslations(locale || DEFAULT_LOCALE, [LOCALES.COMMON, LOCALES.HOME]);

  return {
    props: {
      ...(await propsBuilder.build(lang)),
    },
    revalidate: PAGE_REVALIDATE_TIME,
  };
};

export default Home;
